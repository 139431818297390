import { Navigate, useRoutes } from "react-router-dom";
import useAuth from "../hooks/useAuth";

import { Layout } from "../components";
import { Dashboard, Account, Products, Users, Login, NotFound } from "../pages";

export default function Router() {
  const { user } = useAuth();
  const routes = useRoutes([
    {
      path: "/",
      //element: !user ? <Layout /> : <Navigate to="/login" />,
      element: <Layout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "products", element: <Products /> },
        { path: "account", element: <Account /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    { path: "404", element: <NotFound /> },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
