import React from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Header, Modal, Sidebar } from "../../components";
import { useState } from "react";

const Layout = () => {
  const [isActive, setActive] = useState(false);
  return (
    <React.Fragment>
      <div className="app__container">
        <Sidebar isActive={isActive} setActive={setActive} />
        <Header openSidebar={setActive} />
        <main className="app__main">
          <Outlet />
        </main>
      </div>

      <Toaster
        position="top-left"
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
          style: {
            zIndex: "99999999",
          },
        }}
      />
      <Modal />
    </React.Fragment>
  );
};

export default Layout;
