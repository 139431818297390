import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "reactstrap";

// Firestore
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";

// from firebase configuration
import { storage, db, auth } from "../../../firebase.config";

import toast from "react-hot-toast";
import { Loading } from "../../index";

import { closeModal } from "../../../store/slices/modalSlice";

import "./Styles.css";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

function AddNewUser() {
  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleImgChange = (e) => {
    //to get img url when upload and set into useState[file]
    setFile(e.target.files[0]);
    //to get img and display when upload
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleAddUser = async (data) => {
    const { fname, lname, userName, email, password } = data;
    if (file !== null) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        const user = await userCredential.user;

        const storageRef = ref(storage, `userImages/${fname + lname}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          () => {
            toast.error("profile image not uoploaded");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (downloadURL) => {
                // Update user profile
                await updateProfile(user, {
                  displayName: userName,
                  photoURL: downloadURL,
                });

                // store user data in firestore
                await setDoc(doc(db, "users", user.uid), {
                  uid: user.uid,
                  displayName: userName,
                  email,
                  photoURL: downloadURL,
                  fname,
                  lname,
                });
              }
            );
          }
        );

        setLoading(false);

        toast.success("user added successfully!");

        //setTimeout(() => {}, 2000);

        // close modal after submit
        dispatch(closeModal());
      } catch (error) {
        setLoading(false);
        setImgPreview(null);
        toast.error("wrong! user not added");
      }
    } else {
      toast.error("user's image is required");
    }
  };

  return (
    <div className="add__new__user">
      <h5 className="mb-3">Add a new user</h5>
      <hr className="hr" />
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit(handleAddUser)}>
          <div className="d-flex">
            {/* Upload user's Image */}
            <div className="mb-4 text-center w-50">
              <div
                className={`upload__options ${
                  imgPreview !== null ? "is_uploaded" : ""
                }`}
              >
                <label>
                  <input type="file" onChange={handleImgChange} />
                  {imgPreview !== null ? (
                    <div className="img__preview">
                      <img src={imgPreview} alt="" />
                    </div>
                  ) : null}
                </label>
              </div>
            </div>

            <FormGroup className="d-flex flex-column justify-content-center align-items-center">
              <input
                className={`form-control mb-3 ${
                  errors.fname && "border-danger"
                } `}
                type="text"
                placeholder="first name"
                {...register("fname", {
                  required: "first name is required",
                  minLength: { value: 3, message: "min length is 3" },
                  maxLength: { value: 10, message: "max length is 10" },
                })}
              />
              <input
                className={`form-control mb-3 ${
                  errors.lname && "border-danger"
                } `}
                type="text"
                placeholder="last name"
                {...register("lname", {
                  required: "last name is required",
                  minLength: { value: 3, message: "min length is 3" },
                  maxLength: { value: 10, message: "max length is 10" },
                })}
              />

              <input
                className={`form-control mb-3 ${
                  errors.userName && "border-danger"
                } `}
                type="text"
                placeholder="userName"
                {...register("userName", {
                  required: "user name is required",
                  minLength: { value: 7, message: "min length is 7" },
                  maxLength: { value: 55, message: "max length is 55" },
                })}
              />
            </FormGroup>
          </div>

          <FormGroup className="form__group">
            <input
              className={`form-control ${errors.email && "border-danger"} `}
              type="text"
              placeholder="email address"
              {...register("email", {
                required: "email is required",
                minLength: { value: 3, message: "min length is 3" },
                maxLength: { value: 50, message: "max length is 50" },
                pattern: /^\S+@\S+$/i,
              })}
            />
          </FormGroup>
          <FormGroup className="form__group">
            <input
              className={`form-control ${errors.password && "border-danger"} `}
              type="password"
              placeholder="password"
              {...register("password", {
                required: "password is required",
                minLength: { value: 8, message: "min length is 8" },
                maxLength: { value: 50, message: "max length is 50" },
              })}
            />
          </FormGroup>

          <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-primary w-100 fw-bold" type="submit">
              Add user
            </button>
            <button
              type="button"
              className="btn btn-link text-dark w-75 fw-bold"
              onClick={() => dispatch(closeModal())}
            >
              Close
            </button>
          </div>
        </Form>
      )}
    </div>
  );
}

export default AddNewUser;
