import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "reactstrap";

// Firestore
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";

// from firebase configuration
import { storage, db } from "../../../firebase.config";

import toast from "react-hot-toast";
import { Loading } from "../../index";

import { closeModal } from "../../../store/slices/modalSlice";

import "./Styles.css";

function AddNewProduct() {
  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleImgChange = (e) => {
    //to get img url when upload and set into useState[file]
    setFile(e.target.files[0]);
    //to get img and display when upload
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleAddProduct = async (data) => {
    const { productName, price, category, fullDes, shortDes } = data;
    if (file !== null) {
      /*
      const product = {
        title: productName,
        shortDesc: shortDes,
        description: fullDes,
        category,
        price,
        imgUrl: file,
      };
      */

      try {
        const docRef = await collection(db, "products");

        const storageRef = ref(
          storage,
          `productImages/${Date.now() + file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          () => {
            toast.error("profile image not uoploaded");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (downloadURL) => {
                // add product to firebase
                await addDoc(docRef, {
                  title: productName,
                  shortDesc: shortDes,
                  description: fullDes,
                  category,
                  price,
                  imgUrl: downloadURL,
                });
              }
            );
          }
        );

        setLoading(false);
        toast.success("product added successfully!");

        //setTimeout(() => {}, 2000);

        // close modal after submit
        dispatch(closeModal());
      } catch (error) {
        setLoading(false);
        setImgPreview(null);
        toast.error("wrong! product not added");
      }
    } else {
      toast.error("product's image is required");
    }
  };

  return (
    <div className="add__new__product">
      <h5 className="mb-3">Add a new product</h5>
      <hr className="hr" />
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit(handleAddProduct)}>
          <div className="d-flex">
            {/* Upload product's Image */}
            <div className="mb-4 text-center w-50">
              <div
                className={`upload__options ${
                  imgPreview !== null ? "is_uploaded" : ""
                }`}
              >
                <label>
                  <input type="file" onChange={handleImgChange} />
                  {imgPreview !== null ? (
                    <div className="img__preview">
                      <img src={imgPreview} alt="" />
                    </div>
                  ) : null}
                </label>
              </div>
            </div>

            <FormGroup className="d-flex flex-column justify-content-center align-items-center">
              <input
                className={`form-control mb-3 ${
                  errors.productName && "border-danger"
                } `}
                type="text"
                placeholder="product's name"
                {...register("productName", {
                  required: "product name is required",
                  minLength: { value: 7, message: "min length is 7" },
                  maxLength: { value: 55, message: "max length is 55" },
                })}
              />

              <textarea
                defaultValue="product's short description"
                rows="4"
                className={`form-control ${
                  errors.shortDes && "border-danger"
                } `}
                {...register("shortDes", {
                  required: "product short description is required",
                  minLength: { value: 20, message: "min length is 20" },
                  maxLength: { value: 155, message: "max length is 155" },
                })}
              ></textarea>
            </FormGroup>
          </div>

          <FormGroup className="form__group">
            <textarea
              defaultValue="product's full description"
              rows="5"
              className={`form-control ${errors.fullDes && "border-danger"} `}
              {...register("fullDes", {
                required: "product full description is required",
                minLength: { value: 100, message: "min length is 100" },
                maxLength: { value: 350, message: "max length is 350" },
              })}
            ></textarea>
          </FormGroup>
          <FormGroup className="form__group">
            <select
              className={`form-control w-50 ${
                errors.fullDes && "border-danger"
              } `}
              {...register("category", {
                required: "product category is required",
              })}
            >
              <option>category</option>
              <option value="sofa">sofa</option>
              <option value="chair">chair</option>
              <option value="table">table</option>
              <option value="decor">decor</option>
            </select>
            <input
              className={`form-control w-50 ${
                errors.price && "border-danger"
              } `}
              type="number"
              min={1}
              max={9999}
              placeholder="price"
              {...register("price", {
                required: "product price is required",
                minLength: { value: 1, message: "min length is 1" },
                maxLength: { value: 9999, message: "max length is 9999" },
              })}
            />
          </FormGroup>

          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-primary w-100 fw-bold"
              type="submit"
              onClick={handleSubmit}
            >
              Add product
            </button>
            <button
              type="button"
              className="btn btn-link text-dark w-75 fw-bold"
              onClick={() => dispatch(closeModal())}
            >
              Close
            </button>
          </div>
        </Form>
      )}
    </div>
  );
}

export default AddNewProduct;
