import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase.config";

import { Loading } from "../../../components";
import "./ProductsTable.css";
import { toast } from "react-hot-toast";

const ProductsTable = ({ data, loading }) => {
  const deleteProduct = async (id) => {
    await deleteDoc(doc(db, "products", id));
    toast.success("Product deleted successfully");
  };
  return (
    <div>
      <div className="main-card">
        <div className="table-header">
          <div className="table-search">
            <div className="icon">
              <i className="ri-search-line me-1"></i>
            </div>
            <input type="text" placeholder="search..." />
          </div>
          <button className="btn">
            <i className="ri-filter-3-fill fs-4"></i>
          </button>
        </div>

        {/* Table */}
        {loading ? (
          <Loading />
        ) : data && data.length > 0 ? (
          <table className="main-table">
            <thead>
              <tr>
                <th></th>
                <th>name</th>
                <th>categroy</th>
                <th>price</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td className="table-img">
                    <img src={item.imgUrl} alt={item.title} />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.category}</td>
                  <td>${item.price}</td>
                  <td>
                    <span
                      className="t-btn"
                      onClick={() => deleteProduct(item.id)}
                    >
                      <i class="ri-delete-bin-line fs-5"></i>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center w-100 p-4 fs-4">
            <h4>There are no data</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsTable;
