import React from "react";
import { Link } from "react-router-dom";

const Mcard = ({ icon, num, title, path }) => {
  // generate random colors form the array
  const colors = ["#fdefe6", "#d6e5fb", "#e6c7b9", "#ceebe9", "#e2f2b2"];
  const getBgColor = colors[Math.floor(Math.random() * colors.length)];
  return (
    <div className="Mcard" style={{ backgroundColor: getBgColor }}>
      <Link to={path}>
        <div className="Mcard-icon">
          <i className={icon}></i>
        </div>
        <h3 className="Mcard-num">{num}</h3>
        <h6 className="Mcard-title">{title}</h6>
      </Link>
    </div>
  );
};

export default Mcard;
