import React from "react";

const SearchBox = ({ searchToggle, setSearchToggle }) => {
  return (
    <div className={`search-bar ${searchToggle ? "active" : ""}`}>
      <div className="wrrapper-content">
        <div className="input-box">
          <i className="ri-search-line"></i>
          <input
            type="text"
            className="search-bar-input form-control"
            placeholder="Enter search term or hit click to close"
          />
        </div>
        <button type="button" className="search-bar-button btn btn-primary">
          <i className="ri-search-2-line me-1"></i> search
        </button>
      </div>
      <div
        className="search-overhide"
        onClick={() => setSearchToggle(!searchToggle)}
      ></div>
    </div>
  );
};

export default SearchBox;
