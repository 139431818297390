// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// app Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCsMVyCTBxLIcVYVn9EFQeCXVJ5wn-XH3k",
  authDomain: "multimart-18ed0.firebaseapp.com",
  projectId: "multimart-18ed0",
  storageBucket: "multimart-18ed0.appspot.com",
  messagingSenderId: "411830692877",
  appId: "1:411830692877:web:5944ffd13619b4da24f944",
};
/*
const firebaseConfig = {
  apiKey: process.env.APP_API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MSG_SENDER_ID,
  appId: process.env.APP_ID,
};
*/
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
