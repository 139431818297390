import { useEffect } from "react";
import { db } from "../firebase.config";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { useState } from "react";

const useData = (collectionName) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const collectionRef = collection(db, collectionName);

  useEffect(() => {
    const getDate = async () => {
      await onSnapshot(collectionRef, (snapshot) => {
        setData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
      });
    };

    getDate();
  }, []);
  return { data, loading };
};

export default useData;
