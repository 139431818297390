import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase.config";

import { Loading } from "../../../components";
import "./UsersTable.css";
import { toast } from "react-hot-toast";

const UsersTable = ({ data, loading }) => {
  const deleteUser = async (id) => {
    await deleteDoc(doc(db, "users", id));
    toast.success("Product deleted successfully");
  };
  return (
    <div>
      <div className="main-card">
        <div className="table-header">
          <div className="table-search">
            <div className="icon">
              <i className="ri-search-line me-1"></i>
            </div>
            <input type="text" placeholder="search user" />
          </div>
          <button className="btn">
            <i className="ri-filter-3-fill fs-4"></i>
          </button>
        </div>

        {/* Table */}
        {loading ? (
          <Loading />
        ) : data && data.length > 0 ? (
          <table className="main-table">
            <thead>
              <tr>
                <th></th>
                <th>name</th>
                <th>username</th>
                <th>email</th>
                <th>del.</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item) => (
                <tr key={item.uid}>
                  <td className="table-img">
                    <img src={item.photoURL} alt={item.displayName} />
                  </td>
                  <td>{item.fname + " " + item.lname}</td>
                  <td>{item.displayName}</td>
                  <td>{item.email}</td>
                  <td>
                    <span
                      className="t-btn"
                      onClick={() => deleteUser(item.uid)}
                    >
                      <i class="ri-delete-bin-line fs-5"></i>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center w-100 p-4 fs-4">
            <h4>There are no data</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersTable;
