import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Hemlet, Animated, Mcard } from "../../components";
import useData from "../../hooks/useData";

// css Styles
import "../../styles/Dashboard.css";

const Dashboard = () => {
  const { data: products } = useData("products");
  const { data: users } = useData("users");

  const cards = [
    {
      title: "total sales",
      number: "$3220",
      path: "/products",
      icon: "ri-money-dollar-circle-line",
    },
    { title: "orders", number: 1230, path: "/products", icon: "ri-truck-line" },
    {
      title: "products",
      number: products.length,
      path: "/products",
      icon: "ri-dropbox-fill",
    },
    {
      title: "users",
      number: users.length,
      path: "/users",
      icon: "ri-user-line",
    },
  ];
  return (
    <Hemlet title="Dashboard">
      <Animated>
        {/* Start BEST SELLER */}
        <div className="dashboard">
          <Container>
            <h4 className="p-title">Hi, Welcome back</h4>
            <Row>
              {cards.map((el, idx) => (
                <Col lg="3" md="6" key={idx}>
                  <Mcard
                    title={el.title}
                    num={el.number}
                    icon={el.icon}
                    path={el.path}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        {/* End BEST SELLER section */}
      </Animated>
    </Hemlet>
  );
};

export default Dashboard;
