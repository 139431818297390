import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

// Motion for animation
import { motion } from "framer-motion";

// images
//import images from "../../constants";
// Css Styles
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";

// Firebase
import useAuth from "../../../hooks/useAuth";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase.config";
import toast from "react-hot-toast";
import images from "../../../constants";
import SearchBox from "./SearchBox";

const Header = ({ openSidebar }) => {
  const [searchToggle, setSearchToggle] = useState(false);
  //const searchRef = useRef(null);

  const navigate = useNavigate();

  const navigateToCart = () => {
    navigate("/");
  };
  // const toggleSearch = () => { searchRef.current.classList.toggle('search')}

  const { user } = useAuth();

  //Logout
  const logout = () => {
    signOut(auth)
      .then(() => {
        toast.success("logged out successfully");
      })
      .catch((error) => toast.error(error.message));

    navigate("/login");
  };

  // get global state
  //const { totalQuantity } = useSelector((state) => state.cart);

  return (
    <header className="header">
      <div className="header-content">
        <Container>
          <Row>
            <div className="nav-wrapper">
              {/* nav icons */}
              <div className="nav-icons">
                <div className="left">
                  <span className="menu-btn" onClick={() => openSidebar(true)}>
                    <i className="ri-menu-3-line"></i>
                  </span>
                  <span
                    className="search-btn"
                    onClick={() => setSearchToggle(!searchToggle)}
                  >
                    <i className="ri-search-2-line"></i>
                  </span>
                </div>

                <div className="icons-actions right">
                  {/* language - dropdwon */}
                  <UncontrolledDropdown className="lang-container">
                    <DropdownToggle className="lang-icon" tag="a">
                      <motion.img
                        whileTap={{ scale: 1.1 }}
                        src={images.en}
                        alt="language"
                      />
                    </DropdownToggle>
                    {/* dropdown__menu */}
                    <DropdownMenu className="dropdown__menu">
                      <DropdownItem>
                        <img src={images.de} alt="German" />
                        <a href="#!">German</a>
                      </DropdownItem>

                      <DropdownItem>
                        <img src={images.fr} alt="French" />
                        <a href="#!">French</a>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <span className="nav-icon" onClick={navigateToCart}>
                    <i className="ri-notification-3-line"></i>
                    <span className="badge">3</span>
                  </span>
                  {/* user icon - dropdwon */}
                  <UncontrolledDropdown className="user-container">
                    <DropdownToggle className="user-icon" tag="a">
                      <motion.img
                        whileTap={{ scale: 1.1 }}
                        src={
                          user && user.photoURL !== null
                            ? user.photoURL
                            : images.userIcon
                        }
                        alt="user"
                      />
                    </DropdownToggle>
                    {/* dropdown__menu */}

                    {user ? (
                      <DropdownMenu className="dropdown__menu">
                        <DropdownItem>
                          <Link to="/account/profile"> My profile</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/account"> My Orders</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/account/addresses"> My Addresses</Link>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={logout}>
                          <i className="ri-logout-circle-line"></i> Logout
                        </DropdownItem>
                      </DropdownMenu>
                    ) : (
                      <DropdownMenu className="dropdown__menu">
                        <DropdownItem>
                          <i className="ri-login-circle-line"></i>
                          <Link to="/login">Login</Link>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          <i className="ri-user-received-line"></i>

                          <Link to="/signup">Signup</Link>
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
            {/* search bar */}
            <SearchBox
              searchToggle={searchToggle}
              setSearchToggle={setSearchToggle}
            />
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default Header;
