import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Row } from "reactstrap";

import { motion } from "framer-motion";
import { Animated, Hemlet, Loading } from "../../components";
import toast, { Toaster } from "react-hot-toast";

// Firebase
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.config";

import "../../styles/Login.css";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // React-Form-Hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSignup = async (data) => {
    setLoading(true);

    const { email, password } = data;

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      console.log("user", user);
      setLoading(false);
      toast.success("successfully logged in :)");
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Hemlet title="Login">
      <Animated>
        <section className="login-bg">
          <Container>
            <Row className="my-5">
              <Col lg="6" className="m-auto text-center text-start">
                {loading ? (
                  <Loading />
                ) : (
                  <div className="login-wrapp mt-5">
                    <h2 className="mb-2 fw-bold">Hi, Welcome Back</h2>
                    <h4 className="mb-5">Sign in to Dashboard</h4>
                    <Form
                      className="auth__form mt-4"
                      onSubmit={handleSubmit(handleSignup)}
                    >
                      {/* Email && Username */}
                      <FormGroup className="form__group">
                        <div className="form__group_item">
                          <input
                            className={`form-control ${
                              errors.email && "border-danger"
                            } `}
                            type="text"
                            placeholder="Email"
                            {...register("email", {
                              required: "Email is required",
                            })}
                          />
                          <p className="text-danger">
                            {errors.email ? errors.email.message : null}
                          </p>
                        </div>
                      </FormGroup>

                      {/* Password */}
                      <FormGroup className="form__group">
                        <div className="form__group_item w-100">
                          <input
                            className={`form-control ${
                              errors.password && "border-danger"
                            } `}
                            type="password"
                            placeholder="password"
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                          <p className="text-danger">
                            {errors.password ? errors.password.message : null}
                          </p>
                        </div>
                      </FormGroup>

                      <div className="d-flex justify-content-between ">
                        <p>
                          <Link to="#!" className="text-decoration-underline">
                            Lost your password?
                          </Link>
                        </p>
                      </div>

                      <motion.div whileHover={{ scale: 1.1 }}>
                        <button
                          type="submit"
                          className="btn btn-primary mt-2 w-25 "
                        >
                          Login
                        </button>
                      </motion.div>
                    </Form>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Animated>
      <Toaster
        position="top-left"
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
        }}
      />
    </Hemlet>
  );
};

export default Login;
