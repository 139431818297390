import React from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container } from "reactstrap";
import images from "../../../constants";

import useAuth from "../../../hooks/useAuth";

import "./Sidebar.css";

const Sidebar = ({ isActive, setActive }) => {
  const { user } = useAuth();

  console.log("Sidebar", user);

  const sidebarMenu = [
    { display: "Dashboard", path: "/Dashboard", icon: "ri-bar-chart-fill" },
    { display: "Products", path: "/products", icon: "ri-dropbox-fill" },
    { display: "Users", path: "/users", icon: "ri-user-3-line" },
    { display: "Orders", path: "/", icon: "ri-shape-2-line" },
  ];
  return (
    <aside
      className={`sidebar ${isActive ? "active" : ""}`}
      onClick={() => setActive(!isActive)}
    >
      <div className="sidebar-content">
        <Container>
          <div className="logo">
            <Link to="/">
              <h2>
                Multi <b>Mart</b>
              </h2>
              <span>admin dashboard</span>
            </Link>
          </div>
          <div className="uer-box">
            <div className="user-wrapper">
              <div className="user-img">
                <img
                  src={
                    user && user.photoURL !== null
                      ? user.photoURL
                      : images.userIcon
                  }
                  alt=""
                />
              </div>
              <div className="user-text">
                <span className="name">{user ? user.displayName : null}</span>
                <strong className="logout">Logout</strong>
              </div>
            </div>
          </div>
          <ul className="sidebar-menu">
            {sidebarMenu.map((el, idx) => (
              <NavLink
                key={idx}
                to={el.path}
                className={(navClass) => (navClass.isActive ? "active" : "")}
              >
                <div className="icon">
                  <i className={el.icon}></i>
                </div>
                <div className="text">{el.display}</div>
              </NavLink>
            ))}
          </ul>
        </Container>
      </div>
    </aside>
  );
};

export default Sidebar;
