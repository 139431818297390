import { ScrollToTop } from "./components";
import Router from "./routers/Router";
// General Styles for App
import "./styles/App.css";

function App() {
  return (
    <ScrollToTop>
      <Router />
    </ScrollToTop>
  );
}

export default App;
