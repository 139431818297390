import { configureStore } from "@reduxjs/toolkit";
import modal from "./slices/modalSlice";

const store = configureStore({
  reducer: {
    modal,
  },
});

export default store;
