import { useDispatch } from "react-redux";
import { openModal } from "../../store/slices/modalSlice";
import { Col, Container, Row } from "reactstrap";
import { Animated, Hemlet, PageTitle } from "../../components";
import UsersTable from "./UsersTable/UsersTable";
import useData from "../../hooks/useData";

const Users = () => {
  const { data, loading } = useData("users");
  const dispatch = useDispatch();

  return (
    <Hemlet title="Users">
      <Animated>
        <Container>
          <Row>
            <Col lg="12">
              <PageTitle title="Users" className="mt-4 px-4">
                <button
                  className="btn"
                  onClick={() => dispatch(openModal("AddNewUser"))}
                >
                  <i className="ri-add-fill me-1"></i>
                  Add user
                </button>
              </PageTitle>
            </Col>

            <Col lg="12">
              <UsersTable data={data} loading={loading} />
            </Col>
          </Row>
        </Container>
      </Animated>
    </Hemlet>
  );
};

export default Users;
