import React from "react";

const PageTitle = ({ title, children, className }) => {
  return (
    <div className={`page-title ${className}`}>
      <h3>{title}</h3>
      {children && children}
    </div>
  );
};

export default PageTitle;
