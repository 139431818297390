import logo from "../assets/images/logo.png";
import userIcon from "../assets/images/user-icon.png";
import en from "../assets/images/ic_flag_en.svg";
import de from "../assets/images/ic_flag_de.svg";
import fr from "../assets/images/ic_flag_fr.svg";

// Icons
// export { default as covid19 } from "../Assets/images/icons/covid19.png";
// export { default as order } from "../Assets/images/icons/order.png";

const images = {
  logo,
  userIcon,
  en,
  de,
  fr,
};

export default images;
