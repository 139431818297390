import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "reactstrap";
import images from "../../../constants";
import ScrollAnimation from "../../utility/ScrollAnimation/ScrollAnimation";

function Footer() {
  return (
    <footer className="footer pt-3">
      <hr className="hr-light text-light my-0 mt-3 mb-3" />
      <div className="py-3 text-center">
        <p className="text-light  mb-0">
          <span className="text-light ">
            All copyright reserved &copy; <b>{new Date().getFullYear()}</b>
          </span>{" "}
          | made by{" "}
          <a
            className="text-primary "
            href="https://atef-gad.com"
            target="_blank"
            rel="noreferrer"
          >
            <b>atef gad</b>
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
